export const navSettings = {
  responsive: [{
    breakpoint: 99999,
    settings: {
      arrows: true,
      centerMode: true,
      slidesToShow: 3,
      infinite: false
    }
  },{
    breakpoint: 960,
    settings: {
      arrows: true,
      centerMode: true,
      slidesToShow: 3,
      infinite: false
    }
  }, {
    breakpoint: 640,
    settings: {
      arrows: true,
      centerMode: true,
      centerPadding: '30vw',
      slidesToShow: 1,
      infinite: false
    }
  }, {
    breakpoint: 481,
    settings: {
      arrows: true,
      centerMode: true,
      centerPadding: '25vw',
      slidesToShow: 1,
      infinite: false
    }
  }]
};
