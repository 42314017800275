import { I18nService } from "../utils/i18n.service"
import { PaymentTransactionBase } from "../json-schemas/base/payment_transaction"
import PaymentTransactionSchema from "../../../../../../json_schemas/base/payment_transaction.json"
import { httpDelete, httpGet, httpGetRoute, httpParamsRemoveDiacriticsToSortableNames, httpPost } from "../utils/http.service"
import { PaymentTransactionsStatsResponse } from "../json-schemas/serializers/payment-transactions-stats-response"
import { PaymentTransactionsListResponse } from "../json-schemas/serializers/payment-transactions-list-response"
import { EmployeeBase } from "../json-schemas/base/employee"
import { PaymentTransactionShowResponse } from "../json-schemas/serializers/payment-transaction-show-response"

export type PaymentMode = PaymentTransactionBase['payment_mode'];
export type PaymentModeNoAutomatic = Exclude<PaymentMode, 'automatic'>;

export const paymentTransactionPaymentModes = PaymentTransactionSchema.properties.payment_mode.enum.filter(_ => _ && _ !== 'urssaf_payment') as PaymentMode[]
export const paymentTransactionPaymentModesNoAutomatic = paymentTransactionPaymentModes.filter(_ => _ !== 'automatic') as PaymentModeNoAutomatic[]

export interface RansackQuery {
  'q[s]'?: 'paid_at asc' | 'paid_at desc' | 'amount_cents asc' | 'amount_cents desc'
  'q[id_not_in][]'?: number[]
  'q[paid_at_lteq]'?: string
  'q[paid_at_gteq]'?: string
  'q[paid_at_not_null]'?: boolean
  'q[customerable_of_User_type_sortable_name_or_customerable_of_Supplier_type_sortable_name_or_customerable_of_Marketplace_type_sortable_name_i_cont]'?: string
}

const i18nScoped = new I18nService(require('./payment-transactions.service.yml'))

export function paymentTransactionList (query: RansackQuery = {}): Promise<PaymentTransactionsListResponse> {
  return httpGet(`/${httpGetRoute()}/payment_transactions`, httpParamsRemoveDiacriticsToSortableNames(query))
}

export function paymentTransactionListAsSeenByEmployee (employeeId: EmployeeBase['id'], query: RansackQuery = {}): Promise<PaymentTransactionsListResponse> {
  return httpGet(`/${httpGetRoute()}/employees/${employeeId}/payment_transactions`, httpParamsRemoveDiacriticsToSortableNames(query))
}

export function paymentTransactionShow (id: PaymentTransactionBase['id']): Promise<PaymentTransactionShowResponse> {
  return httpGet(`/${httpGetRoute()}/payment_transactions/${id}`)
}

export function paymentTransactionDelete (id: PaymentTransactionBase['id']) {
  return httpDelete(`/${httpGetRoute()}/payment_transactions/${id}`)
}

export function paymentTransactionReverseAndRefund (id: PaymentTransactionBase['id'], amountCents: number) {
  return httpPost(`/${httpGetRoute()}/payment_transactions/${id}/reverse_and_refund`, { payment_transaction: { amount_to_refund: amountCents } })
}

export function paymentTransactionStats (query: Pick<RansackQuery, 'q[paid_at_gteq]' | 'q[paid_at_lteq]'>): Promise<PaymentTransactionsStatsResponse> {
  return httpGet(`/${httpGetRoute()}/payment_transactions/stats`, httpParamsRemoveDiacriticsToSortableNames(query))
}

export function paymentTransactionRefundInProgressSepaPayment (id: PaymentTransactionBase['id']) {
  return httpPost(`/${httpGetRoute()}/payment_transactions/${id}/refund_in_progress_sepa_payment`, {})
}

export function paymentTransactionStatsAsSeenByEmployee (employeeId: EmployeeBase['id'], query: RansackQuery): Promise<PaymentTransactionsStatsResponse> {
  return httpGet(`/${httpGetRoute()}/employees/${employeeId}/payment_transactions/stats`, httpParamsRemoveDiacriticsToSortableNames(query))
}

export function paymentTransactionI18nPaymentMode (paymentMode: PaymentMode) {
  return i18nScoped.t(`payment_modes.${paymentMode}`)
}

export function paymentTransactionI18nKind (kind: PaymentTransactionBase['kind']) {
  return i18nScoped.t(`kinds.${kind}`)
}

export function paymentTransactionI18nPayeeType (customerableType: PaymentTransactionBase['payee_type']) {
  return i18nScoped.t(`payee_types.${customerableType}`)
}

export function paymentTransactionStripeUrl (providerTransactionId: string) {
  return 'https://dashboard.stripe.com/search?query=' + providerTransactionId
}
