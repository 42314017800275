import $ from 'jquery';
import React from 'react';
import ReactDOM from 'react-dom';
import { ThemeProvider } from 'styled-components';
import { personalizedTheme } from 'typescripts/theme';

export default function MembershipShowCtrl () {
  const $elements = {
    controller: $('[data-controller="MembershipShowCtrl"]'),
    datePickers: $('.date-picker'),
    membershipSuspensionDatePickers: $('.membership-suspension-date-picker'),
    membershipSupplierSuspensionDatePickers: $('.membership-supplier-suspension-date-picker'),
    services: $('.js-services-container'),
    navTabs: $('.profil-nav-link'),
  };

  if ($elements.controller.length > 0) {
    $('.nav-tab-category').hide();
    $('.js-services-container').show();
    if ($elements.services.length > 0 && $elements.navTabs.length > 0) {
      $elements.navTabs.on('click', function () {
        $elements.navTabs.removeClass('is-active');
        const className = $(this).attr('class').split(' ')[0]
        $('.nav-tab-category').hide();
        $(`.${className}-container`).show();
        $(this).addClass('is-active');
      })
    }
    $elements.datePickers.each((key, datePicker) => {
      renderPicker(datePicker.dataset.id, 'suspend_until', document.getElementById(`date-picker-${datePicker.dataset.id}`));
    });
    $elements.membershipSuspensionDatePickers.each((key, datePicker) => {
      renderPicker(datePicker.dataset.id, 'membership[deactivated_marketplace_until]', document.getElementById(`membership-suspension-date-picker-${datePicker.dataset.id}`));
    });
    $elements.membershipSupplierSuspensionDatePickers.each((key, datePicker) => {
      renderPicker(datePicker.dataset.id, 'membership[deactivated_supplier_until]', document.getElementById(`membership-supplier-suspension-date-picker-${datePicker.dataset.id}`));
    });
  }
};

async function renderPicker (id, name, element) {
  const { default: SuspendDayPicker } = await import('components/shared/inputDayPicker');

  ReactDOM.render(
    <ThemeProvider theme={ personalizedTheme(window.marketColors) }>
      <SuspendDayPicker id={ id } past={ false } name={ name} month="fromMonth" />
    </ThemeProvider>
  , element);
}

