import $ from 'jquery';

const UploadMessagingConversationsCustomerOrEmployeeModal = () => {
  const $elements = {
    modalOpener: $('.js-open-modal-upload-messaging_conversation-customer-or-employee'),
  };

  $elements.modalOpener.on('click', openModal);

  function openModal() {
    $.ajax({
      url: $(this).attr('href'),
      success: function(newHTML) {
        const modal = $(newHTML).modal();
        modal.on($.modal.CLOSE, (event, m) => m.elm.remove());
      }
    })

    return false;
  }
};

export default UploadMessagingConversationsCustomerOrEmployeeModal;
