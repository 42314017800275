import * as React from 'react'
import { get } from './lodash.service'

export class I18nService {
  private static I18N_FALLBACKS = {
    'en': ['en', 'en-CA', 'en-US', 'fr'],
    'fr': ['fr', 'fr-CA', 'fr-US', 'en'],
    'fr-CA': ['fr-CA', 'fr', 'fr-US', 'en', 'en-CA'],
    'en-CA': ['en-CA', 'en', 'en-US', 'fr', 'fr-CA'],
    'fr-US': ['fr-US', 'fr', 'fr-CA', 'en'],
    'en-US': ['en-US', 'en', 'en-CA', 'fr'],
    'ka': ['ka', 'en', 'fr'],
    'es': ['es', 'en', 'fr']
  }

  /**
   * @param localeData literal object where root keys are the locale (ex. { fr: any, en; any })
   */
  constructor (public localeData: any) {}

  t (path: string, i18nVars: any = {}, customError?: string) {
    let content = this.findAndPrehandleKey(path, i18nVars, customError)
    for (const key in i18nVars) content = content.replace(`%{${key}}`, i18nVars[key])
    return content
  }

  /**
   * Translate a key a similar way to `t`, but:
   * - the binding could be JSX elements
   * - the result is always a JSX element.
   * 
   * It allows advanced binding with events listeners, links, etc.
   */
  tFragment (path: string, i18nVars: any = {}, customError?: string) {
    const jsxElements: JSX.Element[] = []
    let content = this.findAndPrehandleKey(path, i18nVars, customError)
    
    while (content.length) {
      const match = /%{\w+}/.exec(content)
      jsxElements.push(React.createElement(React.Fragment, { key: jsxElements.length }, match ? content.substring(0, match.index) : content))

      if (match) {
        const varName = match[0].slice(2, -1)
        jsxElements.push(React.createElement(React.Fragment, { key: jsxElements.length }, i18nVars[varName] || `{missing ${varName}}`))
        content = content.substring(match.index + match[0].length)
      } else {
        content = ''
      }
    }

    return jsxElements
  }

  private findAndPrehandleKey (path: string, i18nVars: any = {}, customError?: string) {
    const content = this.findKeyOrFallback(path)

    if (!content) {
      if (typeof customError !== 'undefined') return customError
      if (window.env === 'test') throw new Error(`Unable to translate ${path}`)
      return `Missing translation: ${path}`
    }

    if (typeof i18nVars.count !== 'undefined' && typeof content === 'object') {
      const pluralMap = { 0: 'zero', 1: 'one' }
      return get(content, get(pluralMap, i18nVars.count, 'other'), `Missing translation: ${path}`)
    }

    return content
  }

  private findKeyOrFallback (path: string) {
    // the window object is not available in mocha test-runner (because running in node)
    const locale = typeof window === 'undefined' ? 'fr' : window.locale
    let content = get(this.localeData, locale + '.' + path)
    if (content) return content

    for (const fallbackLocale of (I18nService.I18N_FALLBACKS as any)[locale || 'en']) {
      content = get(this.localeData, fallbackLocale + '.' + path)
      if (content) return content
    }

    return null
  }
}

const i18nScoped = new I18nService(require('./i18n.service.yml'))

export function i18nLocale (locale: string) {
  return i18nScoped.t('locale.' + locale.split('-')[0]) as string
}
