import { I18nService } from "./i18n.service"

// node loader for yml available when running in mocha test-runner
const i18nScoped = new I18nService(require('./utils.service.yml'))
const dayRegex = new RegExp(`(?:^| )(\\d+)${i18nScoped.t('day_symbol')}`)
const hourRegex = new RegExp(`(?:^| )(\\d+)${i18nScoped.t('hour_symbol')}`)
const minuteRegex = new RegExp(`(?:^| )(\\d+)${i18nScoped.t('minute_symbol')}`)

export function isSameDay (d1: Date, d2: Date) {
  if (!d1 || !d2) return false

  d1 = new Date(d1)
  d2 = new Date(d2)
  d1.setHours(0, 0, 0, 0)
  d2.setHours(0, 0, 0, 0)
  return d1.getTime() === d2.getTime()
}

/** 0 => 00:00, 0.5 => 00:30, ..., 16.5 => 16:30 */
 export function formatTime (hoursAndMinutes: number) {
  if (hoursAndMinutes > 24) hoursAndMinutes -= 24
  let hours = Math.trunc(hoursAndMinutes)
  let minutes = Math.round((hoursAndMinutes % 1) * 60)

  if (minutes === 60) {
    // because of rounding issues in hoursAndMinutes, minutes count equal 60.
    hours++
    minutes = 0
  }

  return `${hours < 10 ? '0' + hours : hours}:${!minutes ? '00' : (minutes < 10 ? '0' + minutes : minutes)}`
}

/** Reverse of formatTime() */
export function parseTime (formatedTime: string) {
  const [hours, minutes] = formatedTime.split(':')
  return parseInt(hours, 10) + parseInt(minutes) / 60
}

export function parseTimeRange (from: string, until: string): [number, number] {
  const parsedFrom = parseTime(from)
  const parsedUntil = parseTime(until)
  return [parsedFrom, parsedUntil < parsedFrom ? parsedUntil + 24 : parsedUntil]
}

export function formatDurationFromSecs (durationInSeconds: number, opts: { hideMinIfHours?: boolean, truncSpaces?: boolean, showZeroMinutes?: boolean } = {}) {
  return formatDurationFromHours(Math.round(durationInSeconds / 36) / 100, opts)
}

export function formatDurationFromHours (duration: number, { hideMinIfHours = false, truncSpaces = false, showZeroMinutes = false, showDays = true }: { hideMinIfHours?: boolean, truncSpaces?: boolean, showZeroMinutes?: boolean, showDays?: boolean } = {}) {
  if (!duration) return '0h'

  const parts = []

  if (duration < 0) {
    parts.push('-')
    duration = Math.abs(duration)
  }

  const days = showDays ? Math.trunc(duration / 24) : 0
  const hours = showDays ? Math.trunc(duration % 24) : Math.floor(duration)
  const minutes = Math.round((duration % 1) * 60)
  if (days) parts.push(`${days}${i18nScoped.t('day_symbol')}`)
  if (hours) parts.push(`${hours}${i18nScoped.t('hour_symbol')}`)
  if (minutes || showZeroMinutes) parts.push(`${minutes < 10 ? '0' : ''}${minutes}${hours && hideMinIfHours ? '' : i18nScoped.t('minute_symbol')}`)
  return parts.join(truncSpaces ? '' : ' ')
}

export function parseDurationToSecs (formatedDuration: string) {
  const sign = formatedDuration.startsWith('-') ? -1 : 1
  const days = formatedDuration.match(dayRegex)
  const hours = formatedDuration.match(hourRegex)
  const minutes = formatedDuration.match(minuteRegex)
  return sign * ((days ? parseInt(days[1], 10) * 24 * 3600 : 0) + (hours ? parseInt(hours[1], 10) * 3600 : 0) + (minutes ? parseInt(minutes[1], 10) * 60 : 0))
}

export function utilFormatWordsList (words: string[], middleDelimiter = ', ', lastDelimiter = ` ${ i18nScoped.t('and') } `) {
  return words.length <= 1 ? words.join() : words.slice(0, words.length - 1).join(middleDelimiter) + lastDelimiter + words[words.length - 1]
}

export function utilFormatWordsListWithLimit (words: string[], limit: number) {
  if (words.length <= limit) return utilFormatWordsList(words)
  return utilFormatWordsList(words.slice(0, limit - 1), ', ', ', ') + ' ' + i18nScoped.t('and_x_others', { count: words.length - limit + 1 })
}

export function utilRange (from: number, to: number, step = 1) {
  const array = []
  for (let i = from; i < to; i += step) array.push(i)
  return array
}

export function toggleInArray<T> (array: T[], item: T) {
  return array.includes(item) ? array.filter(_ => _ !== item) : array.concat(item)
}

export function utilTranspose<T> (matrix: T[][]) {
  const newMatric: T[][] = []

  for (let column = 0; column < matrix[0].length; column++) {
    newMatric[column] = []

    for (let row = 0; row < matrix.length; row++) {
      newMatric[column].push(matrix[row][column])
    }
  }

  return newMatric
}

export function removeDiacritics (str: string) {
  return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
}

export function isValidJson (str: string) {
  try {
    JSON.parse(str)
    return true
  } catch (e: unknown) {
    return false
  }
}

export function truncateString (str: string, maxLength: number) {
  return str.length > maxLength ? str.slice(0, maxLength - 3) + '...' : str
}

export function utilGroupBy<T> (items: T[], keyGen: (item: T) => string | number) {
  const groups: { [key: string]: T[] } = {}

  for (const item of items) {
    const key = keyGen(item)
    if (!groups[key]) groups[key] = []
    groups[key].push(item)
  }

  return Object.values(groups)
}

export function partitionArray<T> (elements: T[], predicate: (elem: T) => boolean) {
  const [trueElements, falseElements] = [[], []] as [T[], T[]]

  for (const element of elements) {
    if (predicate(element)) trueElements.push(element)
    else falseElements.push(element)
  }

  return [trueElements, falseElements]
}

export function sleep (ms: number) {
  return new Promise(resolve => setTimeout(resolve, ms))
}
