import $ from 'jquery';
import 'slick-carousel';

// default options
const defaultSlickSettings = {
  arrow: false
};


export const SlickNav = (element, elementChild, slickSettings = defaultSlickSettings) => {
  const $elements = {
    nav: $(element),
    navChild: $(elementChild)
  };

  if ($elements.navChild.length > 1) {
    const indexActive = $elements.nav.find('.is-active').index();
    $elements.nav.slick(slickSettings);
    if (indexActive > -1 && ($elements.navChild.length > 3 || window.innerWidth < 800)) {
      $elements.nav.slick('slickGoTo', indexActive, true);
    }
  }
}
