export const checkStatus = (response) => {
  if (response.status >= 200 && response.status < 300) {
    return response;
  } else {
    // throw error response
    throw response;
  }
};

export const toJson = (response) => {
  if (response.json) {
    return response.json();
  } else {
    return response;
  }
};

export const handleError = (callback) => {
  return (error) => {
    if (error.json) {
      error.json().then((error) => {
        callback(error);
      });
    } else {
      console.error(error);
    }
  };
};

export const jsonHeaders = Object.freeze({
  'Accept': 'application/json',
  'Content-Type': 'application/json'
});
