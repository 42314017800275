import $ from 'jquery';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { moment } from 'typescripts/services/utils/moment.service'
import ease from 'eases/cubic-in-out';
import InlineCalendar from './inlineCalendar';
import { toJson, checkStatus, jsonHeaders } from 'utils/promises';

export default class InlineCalendarContainer extends Component {
  constructor(props) {
    super(props);
    const visibleDaysCount = this.calcVisibleDaysCount($(props.container).width());
    const start = this.offsetDate(props.initialData.start_date, props.alignement, visibleDaysCount);
    this.state = {
      start,
      visibleDaysCount,
      initialDate: start,
      data: props.initialData.events,
      dataRange: moment.range(
        start,
        moment(start).add(props.dataChunkLength.value, props.dataChunkLength.type)),
      width: $(props.container).width(),
      pxDelta: 0,
      animating: false,
    };
  }

  componentWillMount() {
    this.checkData();
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleResize.bind(this));
  }

  componentWillUnmount() {
    if (window) window.removeEventListener('resize', this.handleResize.bind(this));
  }

  offsetDate(startDate, alignement, visibleDaysCount) {
    const start = moment(startDate);

    if (alignement === 'right') return start.subtract(visibleDaysCount - 1, 'days');
    return start;
  }

  calcVisibleDaysCount(containerWidth) {
    const { arrowWidth, dayWidth } = this.props;

    return Math.floor((containerWidth - (2 * arrowWidth)) / dayWidth);
  }

  // GET DATA
  checkData() {
    const { nbSlidingDays, dataChunkLength, alignement } = this.props;
    const { dataRange, visibleDaysCount } = this.state;

    // == preload data in anticipation of next/prev click ==
    if (!this._checkingPrev && !dataRange.contains(moment(this.state.start).subtract(nbSlidingDays, 'days'))) {
      // request previous chunk of data
      const start = moment(dataRange.start);
      this.requestData(alignement === 'right' ? start.subtract(dataChunkLength.value, dataChunkLength.type) : start)
        .then(() => { this._checkingPrev = false; })
        .catch(error => {
          this._checkingNext = false;
        });
      this._checkingPrev = true;
    }
    if (!this._checkingNext && !dataRange.contains(moment(this.state.start).add(visibleDaysCount + nbSlidingDays, 'days'))) {
      // request next chunk of data
      this.requestData(dataRange.end)
        .then(() => { this._checkingNext = false; })
        .catch(error => {
          this._checkingNext = false;
        });
      this._checkingNext = true;
    }
  }

  unionById(array1, array2) {
    const mergedArray = array2.slice();

    for (let i = 0; i < array1.length; i++) {
      const toAdd = array1[i];

      let alreadyFound = false;
      for (let j = 0; j < array2.length && !alreadyFound; j++) {
        const toCmp = array2[j];

        if (toAdd.id === toCmp.id) alreadyFound = true;
      }

      if (!alreadyFound) mergedArray.push(toAdd);
    }

    return mergedArray;
  }

  async requestData(start) {
    const { eventsUrl, dataChunkLength } = this.props;
    const { dataRange } = this.state;

    const response = await fetch(eventsUrl + '?start_date=' + start.format('YYYY-MM-DD'), { method: 'GET', headers: jsonHeaders });
    const { events } = await toJson(checkStatus(response));
    const end = moment(start).add(dataChunkLength.value, dataChunkLength.type);
    this.setState({
      data: this.unionById(events, this.state.data),
      dataRange: moment.range(
        moment.min(start, dataRange.start),
        moment.max(end, dataRange.end)
      ),
    });
  }

  // SLIDE ANIMATION
  slide(dir) {
    const { nbSlidingDays, dayWidth, animDuration } = this.props;
    const { animating, start } = this.state;

    if (animating) { return; }

    let targetStartDate;
    let targetDelta;

    if (dir === 'next') {
      targetStartDate = moment(start).add(nbSlidingDays, 'days');
      targetDelta = nbSlidingDays * dayWidth;
    } else {
      targetStartDate = moment(start).subtract(nbSlidingDays, 'days');
      targetDelta = - nbSlidingDays * dayWidth;
    }

    const initialDelta = 0;
    const initialTime = Date.now();

    const anim = () => {
      const currentTime = Date.now();
      const t = (currentTime - initialTime) / animDuration;
      if (t < 1) {
        this.setState({
          pxDelta: (targetDelta - initialDelta) * ease(t),
          animating: true,
        });
        requestAnimationFrame(anim);
      } else {
        // end of animation
        this.setState({
          start: targetStartDate,
          pxDelta: 0,
          animating: false,
        });
        this.checkData();
      }
    };
    // start animation
    requestAnimationFrame(anim);
  }

  handlePrevious() { this.slide('previous'); }

  handleNext() { this.slide('next'); }

  checkNavDisabled(navName) {
    const { disableNav } = this.props;
    const { start, initialDate } = this.state;

    if (disableNav) {
      if ((navName === 'prev' && disableNav === 'beforeDate') || (navName === 'next' && disableNav === 'afterDate')) {
        return initialDate.isSame(start);
      }
    }
    return false;
  }

  handleResize() {
    const containerWidth = $(this.props.container).width();
    this.setState({
      width: containerWidth,
      visibleDaysCount: this.calcVisibleDaysCount(containerWidth),
    });
    this.checkData();
  }

  render() {
    const { arrowWidth, alignement, dayWidth } = this.props;
    const { start, width, data, visibleDaysCount, pxDelta } = this.state;
    return (
      <div className="timeline">
        <button
          key="prev"
          className="timeline-nav-prev icon-prev"
          style={ { width: arrowWidth + 'px' } }
          onClick={ this.handlePrevious.bind(this) }
          disabled={ this.checkNavDisabled('prev') }
        />
        <InlineCalendar
          key="calendar"
          start={ start }
          width={ width - 2 * arrowWidth }
          data={ data }
          daysCount={ visibleDaysCount }
          pxDelta={ pxDelta }
          alignement={ alignement }
          dayWidth={ dayWidth }
          arrowWidth={ arrowWidth }
        />
        <button
          key="next"
          className="timeline-nav-next icon-next"
          style={ { width: arrowWidth + 'px' } }
          onClick={ this.handleNext.bind(this) }
          disabled={ this.checkNavDisabled('next') }
        />
      </div>
    );
  }
}

InlineCalendarContainer.propTypes = {
  container: PropTypes.object,
  eventsUrl: PropTypes.string,
  alignement: PropTypes.string,
  disableNav: PropTypes.string,
  initialData: PropTypes.object,
  // constants
  dayWidth: PropTypes.number,
  arrowWidth: PropTypes.number,
  animDuration: PropTypes.number,
  nbSlidingDays: PropTypes.number,
  dataChunkLength: PropTypes.object,
};

InlineCalendarContainer.defaultProps = {
  alignement: 'left',
  // constants
  dayWidth: 37,  // px
  arrowWidth: 30,  // px
  animDuration: 1000, // ms
  // offset of X days when clicking on next/prev:
  nbSlidingDays: 7,
  // timerange length of server response (from start_date)
  dataChunkLength: {
    value: 1,
    type: 'month',
  },
};
