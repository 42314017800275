import React from 'react';
import $ from 'jquery';
import { render } from 'react-dom';
import InlineCalendarContainer from 'components/shared/inlineCalendar/inlineCalendarContainer';

const inlineCalendarCtrl = () => {
  const data = window.timelineInitialData;
  const $elements = {
    timeline: $('.js-timeline'),
  };

  const renderInlineCalendar = () => {
    $elements.timeline.each((index, container) => {
      const timelineMode = $(container).data('timeline-mode');
      render(
        <InlineCalendarContainer
          initialData={ data }
          alignement={ timelineMode === 'past' ? 'right' : 'left' }
          eventsUrl={ `${location.origin}/${window.currentUser.type == 'Employee' ? 'account_employee' : 'account'}/events/calendar_next_events.json` }
          disableNav={ timelineMode === 'past' ? 'afterDate' : 'beforeDate' }
          container={ container }
        />, $elements.timeline[0]
      );
    });
  };

  const init = () => {
    if ($elements.timeline.length > 0 && data) renderInlineCalendar();
  };

  init();
};

export default inlineCalendarCtrl;
