import $ from 'jquery';

const UploadPhoneNumbersModal = () => {
  const $elements = {
    modalOpener: $('.js-open-modal-upload-phone_number'),
  };

  const openModal = function(event) {
    $.ajax({
      url: $(this).attr('href'),
      success: function(newHTML, textStatus, jqXHR) {
        const modal = $(newHTML).modal();
        modal.on($.modal.CLOSE, (event, m) => m.elm.remove());
        $('.js-select-noSearch').select2({
          minimumResultsForSearch: Infinity,
        });
      },
    });

    return false;
  };

  $elements.modalOpener.on('click', openModal);
};

export default UploadPhoneNumbersModal;
