import $ from 'jquery';
import { debounce } from 'typescripts/services/utils/lodash.service';

const DeviseCtrl = () => {
  const $elements = {
    userConfirmation: {
      formInputs: $('#js-user-confirmation-form input'),
      password: $('#js-user-confirmation-password'),
      passwordConf: $('#js-user-confirmation-password-conf'),
      submitBtn: $('#js-user-confirmation-submit'),
    },
  };

  const inputMinLength = 8;

  const checkUserConfirmationForm = debounce(() => {
    const { password, passwordConf, submitBtn } = $elements.userConfirmation;
    if (password.val().length >= inputMinLength && passwordConf.val().length >= inputMinLength) {
      submitBtn.attr('disabled', false);
    } else {
      submitBtn.attr('disabled', true);
    }
  }, 300);

  const init = () => {
    $elements.userConfirmation.formInputs.on('change keyup paste', checkUserConfirmationForm)
  };

  if ($('[data-controller="devise"]').length > 0) {
    init();
  }
};

export default DeviseCtrl;
