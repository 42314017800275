import $ from 'jquery';
import 'slick-carousel';

export let sliderDashboard = (parent, child) => {
  if ($(child).length > 2) {
    $(parent).slick({
      infinite: true,
      slidesToShow: 2,
      slidesToScroll: 2,
      responsive: [
        {
          breakpoint: 960,
          settings: {
            infinite: true,
            slidesToShow: 1,
            slidesToScroll: 1,
          }
        }
      ]
    });
  }
};
