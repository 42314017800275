import rollbar from 'rollbar';

const rollbarConfig = {
  accessToken: window.rollbar_token,
  captureIp: true,
  captureUncaught: true,
  captureUnhandledRejections: true,
  itemsPerMinute: 10,
  maxItems: 10,
  payload: {
    person: {
      id: (window.currentUser ? window.currentUser.id : undefined),
      email: (window.currentUser ? window.currentUser.email : undefined),
    },
    environment: window.env,
    client: {
      javascript: {
        source_map_enabled: true,
        code_version: '1.0.0',
        guess_uncaught_frames: true,
      },
    },
  },
  checkIgnore: function(isUncaught, args, payload) {
    // ignore errors from intercom (in scripts received from intercomcdn) and google tag manager
    if (payload && payload.body && payload.body.trace) {
      const stringifyed = JSON.stringify(payload.body.trace)
      if (stringifyed.includes('intercomcdn') || stringifyed.includes('googletagmanager')) return true;
    }

    return false;
  }
};

if (typeof window.rollbar_token === 'undefined') {
  console.error('Missing Rollbar Token !');
  window.Rollbar = console;
} else if (window.rollbar_token.length > 0) {
  window.Rollbar = rollbar.init(rollbarConfig);
} else {
  console.warn('Mocking rollbar using console')
  window.Rollbar = console;
}

export default window.Rollbar;
