import * as React from 'react'
import { createPortal } from 'react-dom'
import deepEqual from 'deep-equal'

export function createPortalInBody (node: React.ReactNode) {
  return createPortal(node, window.document.body)
}

/**
 * Replace a subset of jquery feature we really need (must part of the job mst be in react !)
 * https://github.com/nefe/You-Dont-Need-jQuery
 */

export function domParseHTML (content: string) {
  const context = document.implementation.createHTMLDocument()

  // Set the base href for the created document so any parsed elements with URLs
  // are based on the document's URL
  const base = context.createElement('base')
  base.href = document.location.href
  context.head.appendChild(base)

  context.body.innerHTML = content
  return context.body.children
}

export function domHasRectChanged (oldRect: DOMRect, newRect: DOMRect) {
  return !oldRect && newRect || oldRect && !newRect || !deepEqual(oldRect.toJSON(), newRect.toJSON())
}

export function getWindowHeight () {
  return window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight
}

export function pxToEm (px: number, context = 16) {
  return px / context
}

/**
 * According to rollbar, older browser does not support scrollTo with an object argument (https://rollbar.com/siadmin/OSS-Prod/items/8625/?item_page=0&#instances).
 * This results as an "TypeError: Failed to execute 'scrollTo' on 'Window': 2 arguments required, but only 1 present.".
 */
export function windowScrollTo (options: ScrollToOptions) {
  try {
    window.scrollTo(options)
  } catch (err: unknown) {
    if (err instanceof Error && err.message.includes('2 arguments required')) window.scrollTo(options.left || 0, options.top || 0)
    else throw err
  }
}
