import * as React from 'react'

interface Props { src?: string, initials?: string, className?: string, style?: React.CSSProperties }

export default function AvatarComponent ({ src, initials, className = '', style = {} }: Props) {
  if (src) {
    return <span className={ `avatar ${className}` } style={{ ...style, backgroundImage: `url(${JSON.stringify(src)})` }}></span>
  } else {
    return <span className={ `avatar avatar--placeholder ${className}` } style={ style }>{ initials }</span>
  }
}
