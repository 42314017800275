import { delegate, removeClass, toggleClass } from 'utils/dom';

export const dropdown = (link) => {
  // Note : no jQuery here, since we're requiring it in the casting which doesn't have jQuery has a dependency
  const linkElem = document.querySelectorAll(link);

  const onLinkClick = function(event) {
    event.preventDefault();
    [].forEach.call(linkElem, (el) => {
      if (el !== this) {
        removeClass(el, 'is-active');
      }
    })
    toggleClass(this, 'is-active');
  };

  const onDocumentClick = function() {
    removeClass(linkElem, 'is-active');
  };
  
  window.document.addEventListener('click', delegate(link, onLinkClick, onDocumentClick));
};
