import $ from 'jquery';
import { easeInOutQuad } from 'utils/math';

const toggleSiblings = bool => {
  $(`.${ bool ? 'js-toggle-siblings-if-uncheck' : 'js-toggle-siblings-if-check' }`).each(checkbox => {
    const sibs = $(checkbox).parent().siblings();
    $(checkbox).prop('checked') ? sibs.each(sib => bool ? $(sib).show() : $(sib).hide()) : sibs.each(sib => bool ? $(sib).hide() : $(sib).show());
  });
};

const toggleSibblingsByValue = bool => {
  $(`.${ bool ? 'js-toggle-siblings-if-unset' : 'js-toggle-siblings-if-set' }`).each(input => {
    const sibs = $(input).parent().siblings();
    $(input).val() ? sibs.each(sib => bool ? $(sib).show() : $(sib).hide()) : sibs.each(sib => bool ? $(sib).hide() : $(sib).show());
  });
};

const getBoolValue = hostElem => {
  if (hostElem.type === 'checkbox') return hostElem.checked;
  else if (hostElem.type === 'number') return !!parseInt(hostElem.value, 10);
  return !!hostElem.value.length
}

const computeAttrThen = hostElem => {
  const thenSelector = hostElem.getAttribute('attr-then');
  if (thenSelector) $(thenSelector).toggle(getBoolValue(hostElem));
}

const computeAttrElse = hostElem => {
  const elseSelector = hostElem.getAttribute('attr-else');
  if (elseSelector) $(elseSelector).toggle(!getBoolValue(hostElem));
}

const computeAttrElseDisabled = hostElem => {
  const elseSelector = hostElem.getAttribute('attr-else-disabled');
  if (elseSelector) $(elseSelector).prop('disabled', !getBoolValue(hostElem));
}

const computeAttrRequireAllToEnable = (hostElem, bind = true) => {
  const foreignSelector = hostElem.getAttribute('attr-require-all-to-enable');
  if (!foreignSelector) return;

  $(hostElem).prop('disabled', !!$(foreignSelector).toArray().find(elem => !getBoolValue(elem)));
  if (bind) $(foreignSelector).change(() => computeAttrRequireAllToEnable(hostElem, false));
}

export const initToggleSiblings = () => {
  if ($('.js-toggle-siblings-if-check').length > 0) {
    toggleSiblings(false);
    $('.js-toggle-siblings-if-check').change(() => toggleSiblings(false));
  }

  if ($('.js-toggle-siblings-if-uncheck').length > 0) {
    toggleSiblings(true);
    $('.js-toggle-siblings-if-uncheck').change(() => toggleSiblings(true));
  }

  if ($('.js-toggle-siblings-if-set').length > 0) {
    toggleSibblingsByValue(false);
    $('.js-toggle-siblings-if-set').change(() => toggleSibblingsByValue(false));
  }

  if ($('.js-toggle-siblings-if-unset').length > 0) {
    toggleSibblingsByValue(true);
    $('.js-toggle-siblings-if-unset').change(() => toggleSibblingsByValue(true));
  }
};

export const initAttrThenElse = () => {
  const attrThen = $('[attr-then]');
  const attrElse = $('[attr-else]');
  const attrElseDisabled = $('[attr-else-disabled]');
  const attrRequireAllToEnable = $('[attr-require-all-to-enable]');

  for (let i = 0; i < attrThen.length; i++) computeAttrThen(attrThen[i]);
  for (let i = 0; i < attrElse.length; i++) computeAttrElse(attrElse[i]);
  for (let i = 0; i < attrElseDisabled.length; i++) computeAttrElseDisabled(attrElseDisabled[i]);
  for (let i = 0; i < attrRequireAllToEnable.length; i++) computeAttrRequireAllToEnable(attrRequireAllToEnable[i]);
  
  attrThen.change(event => computeAttrThen(event.target));
  attrElse.change(event => computeAttrElse(event.target));
  attrElseDisabled.change(event => computeAttrElseDisabled(event.target));
};

export const findParentNode = (node, className) => {
  if (node.classList && node.classList.contains(className)) {
    return node;
  } else {
    if (node.parentNode) {
      return findParentNode(node.parentNode, className);
    } else {
      return;
    }
  }
};

export const getScrollHeight = (element) => {
  if (!element) {
    return window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
  } else {
    const bodyRect = document.body.getBoundingClientRect();
    const elemRect = element.getBoundingClientRect();
    const offset = elemRect.top - bodyRect.top;
    return offset;
  }
};

export const isNodeList = (elem) => {
  return typeof elem.length !== 'undefined' && typeof elem.item !== 'undefined';
};

export const hasClass = (element, classN) => {
  return !!element.className.match(new RegExp('(\\s|^)' + classN + '(\\s|$)'));
};

export const addClass = (element, classN) => {
  if (!hasClass(element, classN)) element.className += ' ' + classN;
};

export const removeClass = (element, classN) => {
  const _removeClass = (el) => {
    if (hasClass(el, classN)) {
      const reg = new RegExp('(\\s|^)' + classN + '(\\s|$)');
      el.className = el.className.replace(reg, ' ');
    }
  };
  if (isNodeList(element)) {
    Array.prototype.forEach.call(element, _removeClass);
  } else {
    _removeClass(element);
  }
};

export const toggleClass = (element, classN) => {
  if (hasClass(element, classN)) {
    removeClass(element, classN);
  } else {
    addClass(element, classN);
  }
};

// Mimics event delegation with jquery, without jquery
// Instead of writing the usual '$('document').on('click', '.element', callback)'
// You can use it like :
// window.document.addEventListener('click', delegate('.element', callback, notFoundCallback));
// the callback is triggered when '.element' is found
// the notFoundCallback is triggered if '.element' is not found
// In both callback, this refers to the target element
export const delegate = (element, callback, notFoundCallback) => {
  return function(event) {
    let target = event.target;
    let found = false;

    if (target) {
      // Search for the delegated element
      while (target && target !== this ) {
        // Execute callback when found
        if (target.matches(element)) {
          found = true;
          callback.bind(target)(event); // pass the target element as this
        }
        target = target.parentNode;
      }

      // Execute not found callback
      if (!found && notFoundCallback) notFoundCallback.bind(target)(event);
    }
  };
};

/**
 * Animated Scroll To
 * Derived From https://gist.github.com/james2doyle/5694700
 */
export const scrollTo = (to, callback, duration = 500) => {
  // because it's so fucking difficult to detect the scrolling element, just move them all
  const move = (amount) => {
    document.documentElement.scrollTop = amount;
    document.body.parentNode.scrollTop = amount;
    document.body.scrollTop = amount;
  };

  if (window.env === 'test') return move(to);

  const position = () => {
    return document.documentElement.scrollTop || document.body.parentNode.scrollTop || document.body.scrollTop;
  };

  let start = position(),
    change = to - start,
    currentTime = 0,
    increment = 20;

  const animateScroll = () => {
    // increment the time
    currentTime += increment;
    // find the value with the quadratic in-out easing function
    const val = easeInOutQuad(currentTime, start, change, duration);
    // move the document.body
    move(val);
    // do the animation unless its over
    if (currentTime < duration) {
      window.requestAnimationFrame(animateScroll);
    } else {
      if (callback && typeof(callback) === 'function') {
        // the animation is done so lets callback
        callback();
      }
    }
  };
  animateScroll();
};

export const clearForm = $form => {
  $form.find('input, textarea').val('');
};

export const getPosition = element => {
  const clientRect = element.getBoundingClientRect();
  const scroll = {
    top: document.documentElement.scrollTop || document.body.parentNode.scrollTop || document.body.scrollTop,
    left: document.documentElement.scrollLeft || document.body.parentNode.scrollLeft || document.body.scrollLeft,
  };

  return {
    top: clientRect.top + scroll.top,
    left: clientRect.left + scroll.left,
  };
};
