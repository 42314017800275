import * as React from 'react'
import { Subject } from "rxjs"
import styled, { keyframes } from 'styled-components'

export interface FlashParams {
  content: React.ReactNode | React.ReactNode[]
  type: 'error' | 'warning' | 'success'
  fixed?: boolean
  durationSeconds?: number
}

type InternalFlashParams = FlashParams & { id: number, expiresAt?: number, exiting: boolean }

export const flash$ = new Subject<FlashParams>()

const FlashesContainer = styled.div`
  z-index: 665;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  line-height: 1.2;
  color: white;
`

const fadeInUp = keyframes`
  0% {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
  }
  100% {
    opacity: 1;
    transform: none;
  }
`

const fadeOutDown = keyframes`
  0% {
    opacity: 1;
    transform: none;
  }
  100% {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
  }
`

const Flash = styled.div<{ variant: FlashParams['type'], fixed: boolean, exiting: boolean }>`
  display: flex;
  padding: 1.4em;
  will-change: opacity;
  align-items: center;
  animation: ${props => props.exiting ? fadeOutDown : fadeInUp} 0.5s ease-in-out;
  animation-iteration-count: 1;
  transform-origin: 50% 50%;
  background-color: ${
    props => props.theme.colors[
      props.variant === 'error'
        ? 'clr_error'
        : props.variant === 'warning'
        ? 'clr_2'
        : 'clr_3'
    ]
  };
`

/**
 * This component is mounted once in the application and is responsible for rendering the flash messages.
 */
export default function FlashHostComponent () {
  const [flashes, setFlashes] = React.useState<InternalFlashParams[]>([])

  const closeFlash = React.useCallback((id: number) => {
    setFlashes(flashes => flashes.map(f => {
      if (f.id === id) {
        setTimeout(() => { setFlashes(values => values.filter(f => f.id !== id)) }, 500)
        return { ...f, exiting: true }
      }

      return f
    }))
  }, [])

  React.useEffect(() => {
    const subscription = flash$.subscribe(flash => {
      const internalFlash: InternalFlashParams = { ...flash, id: Date.now(), exiting: false }
      if (!internalFlash.fixed && !internalFlash.durationSeconds) internalFlash.durationSeconds = 8
      if (internalFlash.durationSeconds) internalFlash.expiresAt = Date.now() + internalFlash.durationSeconds * 1000
      setFlashes(flashes => [internalFlash, ...flashes])

      if (internalFlash.expiresAt) {
        setTimeout(() => closeFlash(internalFlash.id), internalFlash.expiresAt - Date.now())
      }
    })

    return () => subscription.unsubscribe()
  }, [closeFlash])

  return <FlashesContainer>
    { flashes.map(flash => <Flash className={ `msgNotif js-test-flash--${flash.type}` } key={ flash.id } variant={ flash.type } fixed={ flash.fixed } exiting={ flash.exiting }>
      <div style={{ flexGrow: 1, textAlign: 'center' }}>{ flash.content }</div>
      <div onClick={ () => closeFlash(flash.id) }><em className='icon-close-alternate u-mls' /></div>
    </Flash>) }
  </FlashesContainer>
}
