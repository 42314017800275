import * as Moment from 'moment-timezone'
import { DateRange, extendMoment } from 'moment-range'

declare module 'moment' {
  interface Moment {
    day(): 0 | 1 | 2 | 3 | 4 | 5 | 6; // to ensure it is safely compared to working days
  }
}

// moment import does not work the same in browser and is nodejs (mocha)
export const moment: ReturnType<typeof extendMoment> = typeof window === 'undefined' ? extendMoment(Moment).default as any : extendMoment(Moment)

// when running specs in mocha, the `window` object is undefined
if (typeof window !== 'undefined') {
  moment.locale(window.locale || 'en')
  if (window.tz && !(window as any).moment_ignore_tz) moment.tz.setDefault(window.tz)
}

if (typeof window === 'object' && window?.env === 'test') {
  (window as any).momentForSpecs = moment
}

export function momentIsFullMonth (from: Moment.Moment, to: Moment.Moment) {
  return from.isSame(from.clone().startOf('month'), 'day') && to.isSame(from.clone().endOf('month'), 'day')
}

export function momentMergeRanges (ranges: DateRange[]) {
  const toMergeRanges = ranges.slice()
  let mergedRanges: DateRange[] = []

  while (toMergeRanges.length) {
    const mergeable = mergedRanges.find(_ => _.add(toMergeRanges[0], { adjacent: true }))

    if (mergeable) {
      mergedRanges = mergedRanges.filter(_ => _ !== mergeable)
      toMergeRanges[0] = mergeable.add(toMergeRanges[0], { adjacent: true })
    } else {
      mergedRanges.push(toMergeRanges[0])
      toMergeRanges.shift()
    }
  }

  return mergedRanges
}

export function formatRelativeDate (date: Moment.Moment) {
  return date.isSame(moment(), 'day') ? date.format('LT') : date.format('ll')
}
