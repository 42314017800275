/**
 * Checks that each external link always opens in a new window.
 * If not, report it to rollbar. The mobile app should NEVER open untrusted links!
 */
export async function checksAllLinksAreSafe () {
  for (const link of Array.from(document.querySelectorAll('a'))) {
    const href = link.getAttribute('href')
    if (
      !href ||
      href.startsWith('/') ||
      href.startsWith('#') ||
      href.startsWith(window.location.origin) ||
      href.startsWith('mailto:') ||
      href.startsWith('data:image') ||
      href.startsWith('tel:') ||
      href.startsWith('zoiper:') ||
      href.startsWith('https://oss.ogustine.com') ||
      href.startsWith('https://www.ogustine.com') ||
      href.startsWith('https://axeptio.eu') || // cause: Google tags manager includes a cookie wall from axeptio
      href.startsWith('https://www.axeptio.eu') ||
      (window.cdn_url && href.startsWith(window.cdn_url))
    ) {
      continue
    }

    const target = link.getAttribute('target')
    if (target === '_blank') continue

    // inside the ck editor, the link may not have safety attributes when freshly appended.
    const elementBranch: HTMLElement[] = [link]
    while (elementBranch[0].parentElement) elementBranch.unshift(elementBranch[0].parentElement)
    if (elementBranch.find(elem => elem.classList.contains('ck-editor'))) continue

    const message = `Found unsafe URL ${href}`
    const alreadyReported: string[] = JSON.parse(sessionStorage.getItem('reported-url') || '[]')
    if (alreadyReported.includes(message)) continue;
    
    (window as any).Rollbar.error(new Error(message))
    alreadyReported.push(message) 
    sessionStorage.setItem('reported-url', JSON.stringify(alreadyReported))
  }
  
  setTimeout(checksAllLinksAreSafe, 5000)
}
