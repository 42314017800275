import React from 'react';
import PropTypes from 'prop-types';
import { moment } from 'typescripts/services/utils/moment.service'
import DayList from './dayList';

InlineCalendar.propTypes = {
  data: PropTypes.array,
  start: PropTypes.object,
  width: PropTypes.number,
  pxDelta: PropTypes.number,
  daysCount: PropTypes.number,
  alignement: PropTypes.string,
  dayWidth: PropTypes.number,
  arrowWidth: PropTypes.number,
};

export default function InlineCalendar({ data, start, width, pxDelta, daysCount, alignement, dayWidth, arrowWidth }) {
  const dayDelta = Math.abs(Math.floor(pxDelta / dayWidth));
  let pxOffset;
  let rangeStart;
  let offset;

  if (pxDelta >= 0) {
    rangeStart = moment(start).add(dayDelta, 'days');
    pxOffset = pxDelta % dayWidth;
  } else {
    rangeStart = moment(start).subtract(dayDelta, 'days');
    pxOffset = pxDelta % dayWidth;
    if (pxOffset < 0) {
      pxOffset += dayWidth;
    }
  }

  const rangeEnd = moment(rangeStart).add(daysCount, 'days');
  const range = moment.range(rangeStart, rangeEnd)
  const days = Array.from(range.by('days'));
  const currentMonth = range.center();

  if (alignement === 'right') {
    offset = ((daysCount * dayWidth) - width);
  } else {
    offset = 0;
  }

  return (
    <div
      className="timeline-list"
      style={ { padding: `0 ${arrowWidth}px` } }
    >
      <div key="month" className="timeline-month">
        { currentMonth.format('MMMM YYYY') }
      </div>
      <DayList
        key="days"
        data={ data }
        days={ days }
        currentMonth={ currentMonth }
        pxOffset={ pxOffset }
        offset={ offset }
        dayWidth={ dayWidth }
      />
    </div>
  );
}
