// Note: always use "credentials: 'same-origin'" even if this is the default value because iOS >= 10 <= 12 does not follow the standard !
import { flash$ } from "../../components/shared/flashes/flash-host.component"
import { I18nService } from "./i18n.service"
import { modalOpenConfirm } from "./modals.service"
import { removeDiacritics } from "./utils.service"

const i18nScoped = new I18nService(require('./http.service.yml'))
const headers = Object.freeze({ 'Accept': 'application/json', 'Content-Type': 'application/json' })
let assetsManifest: { [path: string]: string } = null

if (typeof window !== 'undefined' && window.cdn_url) {
  const inCache = sessionStorage.getItem(`${__DEPLOYED_AT__}-rev-manifest.json`)

  if (inCache) {
    assetsManifest = JSON.parse(inCache)
  } else {
    const request = new XMLHttpRequest()
    request.open('GET', `${window.cdn_url}/rev-manifest.json`, false)  // `false` makes the request synchronous
    request.send(null)
    assetsManifest = JSON.parse(request.responseText)
    sessionStorage.setItem(`${__DEPLOYED_AT__}-rev-manifest.json`, JSON.stringify(assetsManifest))
  }
}

export function httpGetAssetPath (path: string) {
  if  (!window.cdn_url) return `/assets/${path}`
  return `${ window.cdn_url}/assets/${assetsManifest[path]}`
}

export function httpGetRoute () {
  if (window.currentUser?.type === 'Customer') return 'account'
  if (window.currentUser?.type === 'Employee') return 'account_employee'
  if (window.market_type === 'Supplier') return window.currentUser ? 'backoffice' : `suppliers/${window.market_id}`
  if (window.market_type === 'Marketplace') return window.currentUser ? 'market' : `marketplaces/${window.market_id}`
  if (window.market_type === 'Prescriber') return window.currentUser ? 'prescription' : `prescribers/${window.market_id}`
  throw new Error('Unable to resolve route')
}

export function httpGetPublicRoute () {
  return window.market_type.toLowerCase()
}

export async function httpGet (url: string, params: any = {}) {
  return httpProcessResponse(await fetch(url + httpGetQueryParams(params), { method: 'GET', headers, credentials: 'same-origin' }))
}

export async function httpPost (url: string, body: any) {
  return httpProcessResponse(await fetch(url, { method: 'POST', body: JSON.stringify(body), headers: { ...headers, 'X-CSRF-Token': httpGetCSRFToken() }, credentials: 'same-origin' }))
}

export async function httpPostSync (url: string, body: any) {
  const request = new XMLHttpRequest()
  request.open('POST', url, false)  // `false` makes the request synchronous
  request.setRequestHeader('Accept', 'application/json')
  request.setRequestHeader('Content-Type', 'application/json')
  request.send(JSON.stringify(body))
  return JSON.parse(request.responseText)
}

export async function httpPostMultipart (url: string, body: FormData) {
  return httpProcessResponse(await fetch(url, { method: 'POST', body, headers: { 'Accept': 'application/json', 'X-CSRF-Token': httpGetCSRFToken() }, credentials: 'same-origin' }))
}

export async function httpPatch (url: string, body: any) {
  return httpProcessResponse(await fetch(url, { method: 'PATCH', body: JSON.stringify(body), headers: { ...headers, 'X-CSRF-Token': httpGetCSRFToken() }, credentials: 'same-origin' }))
}

export async function httpPatchMultipart (url: string, body: FormData) {
  return httpProcessResponse(await fetch(url, { method: 'PATCH', body, headers: { 'Accept': 'application/json', 'X-CSRF-Token': httpGetCSRFToken() }, credentials: 'same-origin' }))
}

export async function httpPut (url: string, body: any) {
  return httpProcessResponse(await fetch(url, { method: 'PUT', body: JSON.stringify(body), headers: { ...headers, 'X-CSRF-Token': httpGetCSRFToken() }, credentials: 'same-origin' }))
}

export async function httpDelete (url: string, params: any = {}) {
  return httpProcessResponse(await fetch(url + httpGetQueryParams(params), { method: 'DELETE', headers: { ...headers, 'X-CSRF-Token': httpGetCSRFToken() }, credentials: 'same-origin' }))
}

export async function httpRetryable (method: 'POST' | 'PATCH' | 'PUT', url: string, body: any, hostPropertyForSkipable: string) {
  let response, json
  let bodyCopy = JSON.parse(JSON.stringify(body))

  while (true) { // eslint-disable-line no-constant-condition
    response = await fetch(url, { method, body: JSON.stringify(bodyCopy), headers: { ...headers, 'X-CSRF-Token': httpGetCSRFToken() }, credentials: 'same-origin' })
    json = await httpParseJsonAndFlash(response)

    if (json?.skipable_with) {
      if (!await modalOpenConfirm(i18nScoped.t('confirm_continue'), json.confirm_message)) break
      if (hostPropertyForSkipable) bodyCopy[hostPropertyForSkipable] = { ...bodyCopy[hostPropertyForSkipable], ...json.skipable_with }
      else bodyCopy = { ...bodyCopy, ...json.skipable_with }
    } else {
      break
    }
  }

  if (response.status < 200 || response.status >= 300) throw json
  return json
}

export function httpGetQueryParams (params: any) {
  const flattenParams = httpJSONParamsFlatten(params)
  return (flattenParams.length ? '?' : '') + flattenParams.map(p => encodeURIComponent(p.key) + '=' + encodeURIComponent(p.value)).join('&')
}

export function httpGetFormData (params: any) {
  const formData = new FormData()
  for (const { key, value } of httpJSONParamsFlatten(params)) formData.append(key, value)
  return formData
}

/**
 * Transform a nested JSON to a flatten JSON suitable for FormBody multipart and query parameters.
 */
export function httpJSONParamsFlatten (params: any, opts = { keyPrefix: '' }) {
  const flattenParams: { key: string, value: any }[] = []

  for (const key in params) {
    if (typeof params[key] === 'undefined' || params[key] === null || params[key]?.length === 0) continue
    const formattedKey = opts.keyPrefix ? opts.keyPrefix + '[' + key + ']' : key

    if (key === 'q[id_not_in][]') {
      // ApplicationController#uncompress_randack_array will uncompress this array. See related method for explanation.
      flattenParams.push({ key: 'q[id_not_in]', value: params[key].join(',') })
    } else if (Array.isArray(params[key])) {
      for (let index = 0; index < params[key].length; index++) {
        const value = params[key][index]
        const isIndexed = typeof value === 'object' && !(value instanceof File)
        const formattedKeyArray = formattedKey.endsWith('[]') ? formattedKey : formattedKey + `[${isIndexed ? index : ''}]`
        if (isIndexed) flattenParams.push(...httpJSONParamsFlatten(value, { keyPrefix: formattedKeyArray }))
        else flattenParams.push({ key: formattedKeyArray, value })
      }
    } else if (typeof params[key] === 'object' && !(params[key] instanceof File)) {
      flattenParams.push(...httpJSONParamsFlatten(params[key], { keyPrefix: formattedKey }))
    } else {
      flattenParams.push({ key: formattedKey, value: params[key] })
    }
  }

  return flattenParams
}

export function httpGetCSRFToken () {
  // the csrf-token is not defined when running tests with capybara and rspec
  if (window.env === 'test' || window.noCsrf) return undefined

  const metas = document.getElementsByTagName('meta')
  for (let i = 0; i < metas.length; i++) {
    if (metas[i].getAttribute('name') === 'csrf-token') return metas[i].getAttribute('content')
  }

  throw new Error('Unable to find CSRF token')
}

export function httpWithCache<Return, Fn extends (...args: unknown[]) => Promise<Return>> (httpEndpoint: Fn): Fn {
  const cacheItems: Record<string, Return> = {}

  return async function httpWithCacheWrapper (...args: unknown[]) {
    const cacheKey = JSON.stringify(args)
    if (cacheKey in cacheItems) return cacheItems[cacheKey]

    const result = await httpEndpoint(...args)
    cacheItems[cacheKey] = result
    return result
  } as Fn
}

export function httpParamsRemoveDiacriticsToSortableNames<T extends object> (query: T): T {
  const newQuery = { ...query } as Record<string, string>

  for (const key in newQuery) {
    if (key.includes('sortable_name') && newQuery[key]) {
      newQuery[key] = removeDiacritics(newQuery[key].toLocaleLowerCase())
    }
  }

  return newQuery as T
}

function httpProcessResponse (response: Response) {
  // response.status not in the range 200-299
  if (!response.ok) {
    // Clone response to avoid "Body has already been consumed" error when re-reading response body
    // if error uncaught by httpParseJsonAndFlash
    return httpParseJsonAndFlash(response.clone())
      .catch(async () => {
        // We only import Rollbar here due to casting_estimative_price_service_spec.rb (otherwise ReferenceError: window is not defined)
        if (typeof window !== 'undefined') {
          const { default: Rollbar } = await import('../../../utils/rollbar')
          Rollbar.error(
            new Error(`HTTP ERROR ${response.status} - ${response.url} (${response.statusText})`),
            { content: await response.text() }
          )
        }

        throw response
      })
      .then(err => { throw err })
  }

  return response.url.endsWith('.xml') ? response.text() : httpParseJsonAndFlash(response)
}

function httpParseJsonAndFlash (response: Response) {
  return response.json().then(json => {
    if (json.flash) {
      for (const type in json.flash) {
        flash$.next({ content: json.flash[type], type: type as any })
      }
    }

    return json
  })
}

// expose http service for easier testing in dev mode
if (typeof window !== 'undefined') {
  Object.assign(window, { httpGet, httpPost, httpPatch, httpPut, httpDelete })
}
