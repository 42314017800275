import * as React from 'react'

export interface Props {
  className?: string
  rawHtml?: boolean
  message: string | React.ReactNode[]
  alternate?: boolean
  noIcon?: boolean
  noModule?: boolean
  style?: React.CSSProperties
  innerStyle?: React.CSSProperties
  children?: React.ReactNode
  onClick?: React.MouseEventHandler<HTMLDivElement>
}

export default function TooltipComponent ({ message, innerStyle, rawHtml, className, alternate, noIcon, noModule, children, style, onClick }: Props) {
  const renderedMessage = React.useMemo(() => {
    if (Array.isArray(message) && message.length > 0) {
      return <div className="tooltip-inner" style={ innerStyle }>
        { message.length === 1 ? message[0] : <ul>{ message.map((line, index) => <li key={ index }>{ line }</li>) }</ul> }
      </div>
    }
      
    if (typeof message === 'string' && message) {
      return <div className="tooltip-inner" style={ innerStyle } { ...(rawHtml ? { dangerouslySetInnerHTML: { __html: message } } : { children: message }) } />
    }

    return <div className="tooltip-inner" style={ innerStyle }>{ message }</div>
  }, [innerStyle, message, rawHtml])

  const classes = React.useMemo(() => {
    const classes = []
    if (className) classes.push(className)
    if (alternate) classes.push('tooltip--alternate')
    if (noIcon) classes.push('tooltip--noIcon')
    if (!noModule) classes.push('tooltip-container-module')
    return classes
  }, [alternate, className, noIcon, noModule])


  if (!message || (Array.isArray(message) || typeof message === 'string') && !message.length) {
    return <div className={ className } style={ style } onClick={ onClick }>{ children }</div>
  }

  return <div className={ `tooltip ${classes.join(' ')}` } style={ style } onClick={ onClick }>
    { children }
    { renderedMessage }
  </div>
}