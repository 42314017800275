import React from 'react';
import PropTypes from 'prop-types';
import TooltipComponent from 'typescripts/components/shared/tooltip.component';

DayList.propTypes = {
  data: PropTypes.array,
  days: PropTypes.array,
  offset: PropTypes.number,
  pxOffset: PropTypes.number,
  currentMonth: PropTypes.object,
  dayWidth: PropTypes.number,
};

export default function DayList({ data, days, offset, pxOffset, currentMonth, dayWidth }) {
  const makeTooltip = dayData => {
    return <React.Fragment>
      { dayData.map(taskData => <div key={ taskData.id } className="timeline-tooltip-item">
        <div key="col1" className="timeline-tooltip-item-col timeline-tooltip-item-when">{ taskData.time }</div>
        <div key="col2" className="timeline-tooltip-item-col">
          <div key="title" className="timeline-tooltip-item-title">{ taskData.title }</div>
          <div key="who" className="timeline-tooltip-item-who">{ taskData.person }</div>
        </div>
      </div>) }
    </React.Fragment>
  };

  return (
    <div className="timeline-dayList">
      { days.map(d => {
        let dayNumber;
        const numberClassName = 'timeline-list-day-number';
        const dayClassName = 'timeline-list-day';
        const dayClassNames = [dayClassName];
        const dayData = data.filter(eventData => eventData.date === d.format('YYYY-MM-DD'));

        if (dayData.length) {
          dayNumber = <div className={ numberClassName }>
            <TooltipComponent message={ makeTooltip(dayData) } innerStyle={{ width: 'fit-content', transform: 'translateX(calc(-50% + 10px))' }} noIcon noModule>
              <div>{ d.format('D') }</div>
            </TooltipComponent>
          </div>
          dayClassNames.push(dayClassName + '--haveEvent');
        } else {
          dayNumber = <div key="number" className={ numberClassName }> { d.format('D') }</div>;
        }

        // tag days not in current month
        if (d.month() !== currentMonth.month()) dayClassNames.push(dayClassName + '--far');

        return <div key={ d } style={{ display: 'inline-block', width: dayWidth + 'px', transform: 'translateX(-' + pxOffset + 'px)' }} className={ dayClassNames.join(' ') }>
          { dayNumber }
          <div key="name" className="timeline-list-day-name">{ d.format('dd') }</div>
        </div>
      }) }
    </div>
  );
}
