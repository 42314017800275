import * as React from "react"
import { I18nService } from "../../services/utils/i18n.service"

interface Props {
  fullMessages: string[]
  title?: string
  noTitle?: boolean
  className?: string
  style?: React.CSSProperties
  disableAutoScroll?: boolean
  children?: React.ReactElement
}

const i18nScoped = new I18nService(require('./error-box.component.yml'))

export function handleUnknownErrorCode (fullMessages: string[]) {
  return fullMessages.map((msg) => msg === 'unknown_error' ? i18nScoped.t('unknown_error') : msg)
}

export default function ErrorBoxComponent ({ fullMessages, title, noTitle, className, style, disableAutoScroll, children }: Props) {
  const containerRef = React.useRef<HTMLDivElement>()

  React.useEffect(() => {
    if (fullMessages?.length && !disableAutoScroll) {
      // automatically scroll to makes sure the error is in the user screen
      containerRef.current?.scrollIntoView({ behavior: 'smooth' })
    }
  }, [disableAutoScroll, fullMessages?.length])

  if (!fullMessages?.length) return null

  return <div ref={ containerRef } className={ `msgFlash msgFlash--error ${ className ?? 'u-mtm' }` } style={ style }>
    { !noTitle && <p className="msgFlash-title">{ title || i18nScoped.t('default_message') }</p> }
    <ul>{ handleUnknownErrorCode(fullMessages).map((msg, index) => <li key={ index } style={{ whiteSpace: 'pre-wrap' }}>{ msg }</li>) }</ul>
    { children }
  </div>
}
