import * as React from 'react'
import { I18nService } from '../../services/utils/i18n.service'

interface State {
  hasError: boolean
}

const i18nScoped = new I18nService(require('./rollbar.component.yml'))

export default class RollbarComponent extends React.Component<{ children: React.ReactNode }, State> {
  state: State = { hasError: false }

  componentDidCatch (error: any) {
    this.setState({ hasError: true });
    (window as any).Rollbar.error(error)
  }

  render () {
    if (!this.state.hasError) return this.props.children
    
    return <div className="msgFlash msgFlash--error u-mtm">
      <p className="msgFlash-title">{ i18nScoped.t('unknown_error') }</p>
    </div>
  }
}
