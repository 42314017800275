import * as React from 'react'
import { PaymentTransactionBase } from '../json-schemas/base/payment_transaction'
import { errorsSetFlash } from '../utils/errors.service'
import { httpGetQueryParams, httpGetRoute, httpPost } from '../utils/http.service'
import { I18nService } from '../utils/i18n.service'
import { paymentTransactionShow } from './payment-transactions.service'

const i18nScoped = new I18nService(require('./stripe-terminals.service.yml'))

export function stripeTerminalsConnectionToken (): Promise<{ secret_key: string }> {
  return httpPost(`/${httpGetRoute()}/stripe_terminals/connection_token`, {})
}

export function stripeTerminalsDefaultStripeLocation (): Promise<{ stripe_location_id: string }> {
  return httpPost(`/${httpGetRoute()}/stripe_terminals/default_stripe_location`, {})
}

export function stripeTerminalsCancelsStripeTerminalPaymentTransaction (paymentTransactionId: PaymentTransactionBase['id'], errorMessage: string) {
  const body = { id: paymentTransactionId, error_message: errorMessage }
  return httpPost(`/${httpGetRoute()}/stripe_terminals/cancel_stripe_terminal_payment_transaction`, { payment_transaction: body })
}

export async function stripeTerminalInitLocation () {
  // the Stripe terminal SDK requires the precise location permission to be granted.
  // see https://stripe.com/docs/terminal/payments/setup-integration?terminal-sdk-platform=android#configure
  const hasLocationPermission = await window.ogustineBridge.queryPreciseLocationPermission().then(() => true).catch(() => false)
  if (!hasLocationPermission) throw { full_messages: [i18nScoped.t('error_location_permission')] }
}

export async function stripeTerminalInitBluetooth () {
  const hasBluetoothPermission = await window.ogustineBridge.queryBluetoothPermission().then(() => true).catch(() => false)
  if (!hasBluetoothPermission) throw { full_messages: [i18nScoped.t('error_bluetooth_permission')] }

  const isBluetoothEnabled = await window.ogustineBridge.isBluetoothEnabled()
  if (isBluetoothEnabled === null) throw { full_messages: [i18nScoped.t('error_bluetooth_not_available')] }
  if (!isBluetoothEnabled) throw { full_messages: [i18nScoped.t('error_bluetooth_disabled')] }
}

export async function stripeTerminalInitSDK () {
  if (await window.ogustineBridge.isStripeTerminalInitialized()) {
    // if the SDK was already initialized, ensures it is in a clean state
    await window.ogustineBridge.stopDiscoveringStripeTerminalReaders()
  } else {
    // The SDK will automatically asks for the connection token when needed (and may re-ask it as any time).
    // The JS part is handled in stripeTerminalRegisterConnectionTokenEventListener() of the cordova driver.
    await window.ogustineBridge.initializeStripeTerminal()
  }
}

export async function stripeTerminalDiscoverRememberedReader () {
  const serialNumber = await stripeTerminalGetRememberedReaderSerialNumber()
  if (!serialNumber) throw { full_messages: [i18nScoped.t('error_no_reader_serial_number')] }

  await new Promise((resolve, reject) => {
    window.ogustineBridge.startDiscoveringStripeTerminalReaders((error, readers) => {
      if (error) return reject({ full_messages: [i18nScoped.t('error_discovering_readers')] })
      
      const reader = readers.find(_ => _.serialNumber === serialNumber)
      if (reader) window.ogustineBridge.stopDiscoveringStripeTerminalReaders().then(() => resolve(reader)).catch(reject)
    })
  })
}

export async function stripeTerminalConnectBluetoothReader (reader: StripeTerminalReader, updateListener: StripeReaderListener) {
  const locationId = await stripeTerminalsDefaultStripeLocation().then(_ => _.stripe_location_id)
  const newUpdateListener: StripeReaderListener = {
    ...updateListener,
    onFinishInstallingUpdate: errorMessage => {
      if (errorMessage) errorsSetFlash([i18nScoped.t('failed_to_install_update', { error_message: errorMessage })])
      updateListener.onFinishInstallingUpdate(errorMessage)
    }
  }

  await window.ogustineBridge.connectBluetoothStripeTerminalReader(reader.serialNumber, locationId, newUpdateListener)
}

export function stripeTerminalSetRememberedReaderSerialNumber (serialNumber: string) {
  window.localStorage.setItem('stripe_terminal_reader_serial_number', serialNumber)
}

export async function stripeTerminalGetRememberedReaderSerialNumber () {
  return window.localStorage.getItem('stripe_terminal_reader_serial_number')
}

export async function stripeTerminalCollectPaymentMethod (paymentIntentClientSecret: string, paymentTransactionId: PaymentTransactionBase['id']) {
  try {
    await window.ogustineBridge.collectPaymentMethodUsingStripeTerminal(paymentIntentClientSecret)
  } catch (error) {
    if (typeof error === 'object' && 'isCordova' in error) {
      // In case of a Cordova error (ex. user cancelling the payment from the Terminal), stripe does not send a webhook to the backend.
      // We need to manually cancel the payment on ogustine to avoid the payment being stuck in a "processing" state.
      // If it appends the payment is succesfully depites the error, the backend will be notified by the webhook and the payment will be updated to "success".
      await stripeTerminalsCancelsStripeTerminalPaymentTransaction(paymentTransactionId, error.message)
      throw { full_messages: [error.message] }
    }

    throw error
  }

  // wait for the payment to be processed by the backend (which is notified of a successful payment by the Stripe webhook)
  for (let i = 0; i < 30; i++) {
    await new Promise(resolve => setTimeout(resolve, 1000))

    const { paymentTransaction } = await paymentTransactionShow(paymentTransactionId)
    if (paymentTransaction.status !== 'processing') break
  }
}

export function stripeTerminalIsUsable (
  connectedReader: StripeTerminalReader | null,
  bridgeCommand: 'getConnectedStripeTerminalReader' | 'collectPaymentMethodForSetupIntentUsingStripeTerminal',
  tpeParams: { customer_id?: number } | { invoice_id?: number } = null
) {
  if (!window.cordova) {
    return { reason: i18nScoped.t('error_not_on_cordova'), usable: false }
  }

  if (!window.ogustineBridge || !window.ogustineBridge[bridgeCommand]) {
    return { reason: i18nScoped.t('error_outdated_application'), usable: false }
  }

  if (!connectedReader?.id) {
    const reason = <>
      <p>{ i18nScoped.t('error_please_first_connect_your_terminal') }</p>
      <a href={ `/${httpGetRoute()}/stripe_terminals${httpGetQueryParams(tpeParams)}` }>{ i18nScoped.t('error_pair_a_terminal') }</a>&nbsp;
    </>

    return { reason, usable: false }
  }

  return { reason: null, usable: true }
}
