import $ from 'jquery';
import endpoints from 'constants/shared/endpoints.const';
import { toJson, checkStatus } from 'utils/promises';
import { httpGetCSRFToken } from 'typescripts/services/utils/http.service';
import 'select2';
import 'select2/dist/js/i18n/fr';

const NewMarketCtrl = () => {
  const $elements = {
    newMarketPage: $('[data-controller="NewMarketCtrl"]'),
    countrySelect: $('#js-country-select'),
  };

  const changeAvailablePlans = () => {
    return {
      promise: new Promise((resolve, reject) => {
        fetch(endpoints.changeAvailablePlans(window.controller_name, $elements.countrySelect.val()), {
          method: 'get',
          credentials: 'same-origin',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'X-CSRF-Token': httpGetCSRFToken(),
          },
        })
        .then(checkStatus)
        .then(toJson)
        .then( response => {
          if (response) {
            $('#js-subscriptionable-id-select-container').show()
            $('#js-subscriptionable-select').html("").select2({
              minimumResultsForSearch: Infinity,
              data: response.plans
            })
            if (response.plans.length == 0) $('#js-subscriptionable-id-select-container').hide();
          }
        })
        .catch(error => {
          reject(error);
        });
      }),
    };
  }

  const init = () => {
    $elements.countrySelect.on('change', () => {
      changeAvailablePlans();
    })
  };
  if ($elements.newMarketPage.length > 0) init();
};

export default NewMarketCtrl;
