import deepEqual from 'deep-equal';

/**
 * Replace the smaller subset of lodash feature we really need.
 * See more at https://github.com/you-dont-need/You-Dont-Need-Lodash-Underscore.
 */

export function get (obj: any, path: any, defaultValue: any = undefined) {
  const travel = (regexp: any) =>
    String.prototype.split
      .call(path, regexp)
      .filter(Boolean)
      .reduce((res: any, key: any) => (res !== null && res !== undefined ? res[key] : res), obj);
  const result = travel(/[,[\]]+?/) || travel(/[,[\].]+?/);
  return result === undefined || result === obj ? defaultValue : result;
}

export function debounce<F extends Function>(func: F, wait: number, immediate = false): F {
  let timeout: number;

  return function() {
    // @ts-ignore
    const context = this, args = arguments;

    return new Promise((resolve) => {
      clearTimeout(timeout);
      if (immediate && !timeout) resolve(func.apply(context, args));

      timeout = window.setTimeout(function() {
        timeout = null;
        if (!immediate) resolve(func.apply(context, args));
      }, wait);
    })
  } as any
}

export function onlyUniqueByReference (value: any, index: number, self: any[]) { 
  return self.indexOf(value) === index;
}

export function onlyUniqueByValue (value: any, index: number, self: any[]) {
  return self.findIndex(_ => deepEqual(_, value)) === index;
}

export function onlyUniqueByTransform<T> (transform: (arg: T) => any) {
  return function (value: T, index: number, self: T[]) { 
    return self.findIndex(_ => transform(_) === transform(value)) === index;
  }
}

export function capitalize (str: string) {
  return str ? str[0].toUpperCase() + str.substring(1) : '';
}

export function intersection<T> (arrays: T[][]) {
  return arrays.length ? arrays.reduce((a, b) => a.filter(c => b.includes(c))) : [];
}

/**
 * Take items in array until `accumulator` returns false.
 * First argument of `accumulator` is an accumulated value, initilized with `initialValue`.
 */
export function takeWhile<T, U> (array: T[], accumulator: (acc: U, curr: T, index: number, array: T[]) => U | false, initialValue: U) {
  const taken = [];
  let currentValue: U | false = initialValue;

  for (let i = 0; i < array.length; i++) {
    currentValue = accumulator(currentValue, array[i], i, array);
    if (currentValue === false) return taken;
    taken.push(array[i])
  }
  
  return taken;
}
