import $ from 'jquery';
import 'jquery-modal';

export const modal = () => {
  $.modal.defaults = {
    overlay: '#000', // Overlay color
    opacity: 0.5, // Overlay opacity
    zIndex: 666, // Overlay z-index.
    escapeClose: false, // Allows the user to close the modal by pressing `ESC`
    clickClose: false, // Allows the user to close the modal by clicking the overlay
    closeText: 'Close', // Text content for the close <a> tag.
    closeClass: 'buttonClose', // Add additional class(es) to the close <a> tag.
    showClose: true, // Shows a (X) icon/link in the top-right corner
    modalClass: 'modal-container', // CSS class added to the element being displayed in the modal.
    spinnerHtml: null, // HTML appended to the default spinner during AJAX requests.
    showSpinner: true, // Enable/disable the default spinner during AJAX requests.
    fadeDuration: null, // Number of milliseconds the fade transition takes (null means no transition)
    fadeDelay: 1.0 // Point during the overlay's fade-in that the modal begins to fade in (.5 = 50%, 1.5 = 150%, etc.)
  };

  // Append '?modal=true' to jquery modal links.
  // By default, jquery modal follows redirect in ajax and display a modal with redirected page.
  // In case of policy failure or logout, the user has 2 nested oss pages, with results in various bugs.
  // The server use params[:modal] to mitigate this issue (it returns an html fragment with JS redirection).
  $('[rel="modal:open"]').each(function () {
    const href = $(this).attr('href');
    if (href && href.startsWith('/')) $(this).attr('href', href + (href.includes('?') ? '&' : '?') + 'modal=true')
  })
}
